/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		'common': {
			init: function () {
				// JavaScript to be fired on the home page

				$("#main-site-nav-toggle .navbar-toggle").on("click", function(){
					$("#main-site-slide-in-nav").addClass("open");
				});

				$("#main-site-slide-in-nav .close-btn").on("click", function(){
					$("#main-site-slide-in-nav").removeClass("open");
				});

			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page



			}
		},
		'page_template_page_event_venue' : {
			init: function(){
				// JavaScript to be fired on the event venue page

				
			}
		},
		// Agenda page
		'page_template_page_event_agenda': {
			init: function () {
				// JavaScript to be fired on the event agenda page

				$('.agenda-tab-group a[data-toggle]').on("click", function(){

					$(".agenda-tab.active").removeClass("active")

					$(this).toggleClass("active");

				});

				// Keep carousel from transitioning other than on click
				$('.agenda-table-session-carousel .carousel').carousel({
					pause: true,
					interval: false
				});

				$(".agenda-table-session-carousel").on("slide.bs.carousel", function(evt){

					var carouselData = $(this).find(".carousel").data("bs.carousel");

					var currSlideNum = carouselData.getItemIndex($(this).find(evt.relatedTarget));

					$(this).find(".carousel-indicators .btn")
						.removeClass("active")
						.filter('[data-slide-to="' + currSlideNum + '"]')
							.addClass("active");

				});

				window.onbeforeprint = function() { Tawk_API.hideWidget(); };
				window.onafterprint = function() { Tawk_API.showWidget(); };

				$('#print-agenda').on('click', function() {

					window.print();

				});

			}
		},
		// Accordion agenda page
		'page_template_page_event_accordion_agenda': {
			init: function () {
				// JavaScript to be fired on the event accordion agenda page

				$('.agenda-tab-group a[data-toggle]').on("click", function(){
					$(".agenda-tab.active").removeClass("active")
					$(this).toggleClass("active");
				});

				$('#expand-all-toggle').on('click', function(){
					$('.panel-collapse:not(".in")').collapse('show');
				});
			
				$('#collapse-all-toggle').on('click', function(){
					$('.panel-collapse.in').collapse('hide');
				});

				$('.panel').on('show.bs.collapse', function(){
					$(this).parents('.session-details').find('.expand-collapse-icon').addClass('rotate');
				});

				$('.panel').on('hide.bs.collapse', function(){
					$(this).parents('.session-details').find('.expand-collapse-icon').removeClass('rotate');
				});

				$(document).on('click', '.line-item-container.accordion-toggle .session-content a', function(e){
					e.stopPropagation();
				});

				window.onbeforeprint = function() {
					Tawk_API.hideWidget();
				};

				window.onafterprint = function() {
					Tawk_API.showWidget();
				};

				$('#print-agenda').on('click', function() {
					window.print();
				});

			}
		},
		// Agenda preview page
		'page_template_page_event_agenda_preview': {
			init: function() {
				// Javascript to be fired on the event agenda preview page

				initializeSmartForm();

			}
		},
		// Webinar page
		'page_template_page_event_webinar': {
			init: function() {
				// Javascript to be fired on the event webinar page

				initializeSmartForm();

			}
		},
		// Landing page
		'page_template_page_event_landing': {
			init: function() {
				// Javascript to be fired on the event landing page

				initializeLandingPage();
				$('.page-template-page-event-landing').css('opacity', '1');

			}
		},
		// Landing download page
		'page_template_page_event_landing_download': {
			init: function() {
				// Javascript to be fired on the event landing download page

				initializeLandingPage();
				$('.page-template-page-event-landing-download').css('opacity', '1');

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	var initializeSmartForm = function() {

		$('html').css('scroll-behavior', 'smooth');

		$(document).on('nfFormReady', function(){

			// Move Canada, United States and United Kingdom to top of Country selection list
			var companySite = /\.(.+)/.exec(window.location.hostname)[1];
			
			if (companySite == 'canadianinstitute.com') {
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'americanconference.com') {
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'c5-online.com') {
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}

			// Configure tab index
			$('.smart-form input[name="fname"]').attr('tabindex', '2');
			$('.smart-form input[name="lname"]').attr('tabindex', '3');
			$('.smart-form input[name="position"]').attr('tabindex', '4');
			$('.smart-form input[name="company"]').attr('tabindex', '5');
			$('.smart-form .listcountry-wrap select').attr('tabindex', '6');
			$('.smart-form input[name="email"]').attr('tabindex', '7');
			$('.smart-form input[name="business_phone"]').attr('tabindex', '8');
			$('.smart-form input[name="mobile_phone"]').attr('tabindex', '9');

			// Email consent conditional logic
			var consentCountries = ['AU', 'CH', 'CA', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var gdprCountries = ['AU', 'CH', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var emailConsentFieldID = $('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .field-wrap').data('field-id');

			nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);

			$('.smart-form').on('input propertychange paste', '.listcountry-container select, .email-container input', function() {

				var reqConsent = true; // This value being set to 'true' is currently overriding the consent countries check and requiring consent for all countries. To enable default behaviour (only countries in the consentCountries array require consent), change this value to 'false'.
				var forceConsent = true;
				var submitHoverTriggered = false;

				$.each(consentCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						reqConsent = true;
						return false;
					}

				});

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						forceConsent = false;
						submitHoverTriggered = false;
						return false;
					} else {
						submitHoverTriggered = true;
					}

				});

				if ($('.smart-form .email-container input').val() != '' && reqConsent) {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', true);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

					if(forceConsent) {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 1);
					} else {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 0);
					}

				} else {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

				}

				$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').off('change.gdprCountry');
				$('.smart-form .consent-not-required-msg').remove();

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {

						$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container input').on('change.gdprCountry', function() {

							if (this.checked) {
								$('.smart-form .consent-not-required-msg').remove();
							} else {
								if ($('.smart-form .consent-not-required-msg').length === 0) {
									$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
								}
							}
							submitHoverTriggered = true;

						});

						return false;
						
					}

				});

				$('.smart-form .nf-field-container.submit-container').on('mouseenter', function() {

					if (!submitHoverTriggered && $('.smart-form .email-container input').val() != '') {
						if ($('.smart-form .consent-not-required-msg').length === 0) {
							$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
						}
						$(window).scrollTop($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(4)').offset().top - 150);
						submitHoverTriggered = true;
					}
	
				});

			});

		});

	}

	var initializeLandingPage = function() {

		$('.event-breadcrumb-callout, header.navbar-default, #main-site-slide-in-nav, .event-nav-wrap, .event-title-bar, #event-contact-block, #mailing-list-cta, footer, .share-event-btns-wrap').remove();

		var eventTitleComponent = $('.landing-header .event-title, .landing-download-header .event-title');
		var eventTitlePrefixComponent = $('.landing-header .event-title-prefix, .landing-download-header .event-title-prefix');
		var eventDateComponent = $('.landing-header .event-date, .landing-download-header .event-date');
		var eventLocationComponent = $('.landing-header .event-location, .landing-download-header .event-location');

		if (eventTitleComponent.text() == '' && eventTitleComponent.html() == '') {
			eventTitleComponent.html($.parseHTML(eventTitle));
		}
		if (eventTitlePrefixComponent.text() == '' && eventTitlePrefixComponent.html() == '') {
			eventTitlePrefixComponent.html($.parseHTML(eventTitlePrefix));
		}
		if (eventDateComponent.text() == '' && eventDateComponent.html() == '') {
			eventDateComponent.html($.parseHTML(eventDate));
		}
		if (eventLocationComponent.text() == '' && eventLocationComponent.html() == '') {
			eventLocationComponent.html($.parseHTML(eventLocation));
		}

		$(document).on('nfFormReady', function() {

			// Move Canada, United States and United Kingdom to top of Country selection list
			var companySite = /\.(.+)/.exec(window.location.hostname)[1];

			if (companySite == 'canadianinstitute.com') {
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'americanconference.com') {
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'c5-online.com') {
				$($('.listcountry-wrap option[value="GB"]')).insertAfter('.listcountry-wrap select option:first-of-type');
				$($('.listcountry-wrap option[value="US"]')).insertAfter('.listcountry-wrap select option:nth-of-type(2)');
				$($('.listcountry-wrap option[value="CA"]')).insertAfter('.listcountry-wrap select option:nth-of-type(3)');
			}

			// Configure tab index
			$('input[name="fname"]').attr('tabindex', '2');
			$('input[name="lname"]').attr('tabindex', '3');
			$('input[name="company"]').attr('tabindex', '4');
			$('input[name="position"]').attr('tabindex', '5');
			$('input[name="email"]').attr('tabindex', '6');
			$('.listcountry-wrap select').attr('tabindex', '7');

		});

		// Remove Tawk.to widget
		$(window).load(function() {

			Tawk_API.onLoad = function() { Tawk_API.hideWidget(); };

		});

	}

})(jQuery); // Fully reference jQuery after this point.
